import './footer.css';

export default function Footer(){

  return (
    <div className='footer__wrapper'>
      <div className='footer__copyright'>
        Michael Slaton ©
      </div>
    </div>
  )
};